import "katex/dist/katex.min.css";
import "@/themes/index.less";
import NormalMessage from "./NormalMessage";
import ResolveMessage from "./ResolveMessage";

function MessageContentRender({ msg, setText }) {
  let { content, type, position, status } = msg;

  if (position === "left" && content?.message_type?.includes("scholar")) {
    try {
      JSON.parse(content.text);
    } catch (error) {
      content = { ...content, message_type: "text" };
    }
  }

  switch (type) {
    case "normal-message":
      return (
        <NormalMessage
          messageType={content.message_type}
          text={content?.text}
          createdAt={content?.createdAt}
          setComposerText={setText}
          messageId={content?.messageId}
          position={position}
          isLast={content?.isLast}
          questionType={content?.question_type}
          placeholderType={content?.placeholder_type}
          status={content?.status}
          isContent={content?.isContent}
          config={content?.config}
        />
      );
    case "resolve-message":
      return (
        <ResolveMessage
          setComposerText={setText}
          messageId={content?.messageId}
        />
      );
    default:
      return null;
  }
}

export default MessageContentRender;
