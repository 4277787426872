import { Tooltip, Button as AntdButton, Upload, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash-es";
import styles from "./index.module.less";

export default function ArticleUpload({ setFileList, setSendDisable, fileList }) {
  const handleChange = (info) => {
    const { fileList } = info;
    const newFileList = fileList
      .map((file) => {
        return {
          ...file,
          loading: !file.response && !file.uploadError,
          error: file.uploadError,
        };
      })
      .filter((file) => !file.error);
    setFileList(newFileList);
    const loading = !isEmpty(newFileList.filter((file) => file.loading));
    setSendDisable(loading);
  };

  const uploadProps = {
    accept: ".pdf,.docx,.txt",
    action: "/api/usermedia/upload_media/",
    onChange: handleChange,
    multiple: true,
    beforeUpload: (file) => {
      if (file.size > 10 * 1024 * 1024) {
        file.uploadError = true;
        message.error(`${file.name}文件大小不能超过 10 MB`);
        return false;
      }
    },
    showUploadList: false,
  };

  return (
    <Tooltip
      placement="top"
      title="上传文档"
    >
      <Upload
        {...uploadProps}
        fileList={fileList}
        className={styles.customToolbarUpload}
      >
        <AntdButton
          type="text"
          icon={<CloudUploadOutlined />}
          size="large"
        />
      </Upload>
    </Tooltip>
  );
}
