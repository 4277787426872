export const isElementScrolledBottom = (element) => {
  return element.scrollTop > 0 && element.scrollHeight - element.scrollTop >= element.clientHeight - 54;
};
// input输入loading和发送按钮禁用/启用
export function disableComposer() {
  const el = document.querySelector(".Composer-input");
  const sendBtn = document.querySelector(".chatBoottom-sendBtn");
  const spinner = document.querySelector(".input-spinner");
  if (!el) return;
  el.disabled = true;
  el.style.cursor = "not-allowed";
  if (sendBtn) {
    sendBtn.disabled = true;
  }
  if (spinner) {
    spinner.style.display = "block";
  }
}

export function enableComposer() {
  const el = document.querySelector(".Composer-input");
  const sendBtn = document.querySelector(".chatBoottom-sendBtn");
  const spinner = document.querySelector(".input-spinner");
  if (!el) return;
  el.disabled = false;
  el.style.cursor = "text";
  // el.focus();
  if (sendBtn) {
    sendBtn.disabled = false;
  }
  if (spinner) {
    spinner.style.display = "none";
  }
}

// 删除message更改选中背景色
export function setBubbleBgColor(messageId, show) {
  const q = document.querySelector(`div.Message[data-id='${messageId}-question']`);
  const a = document.querySelector(`div.Message[data-id='${messageId}-answer']`);
  if (!(q && a)) {
    return false;
  }
  if (show) {
    q.style.backgroundColor = "rgba(var(--brand-rgb-1), 0.1)";
    a.style.backgroundColor = "rgba(var(--brand-rgb-1), 0.1)";
  } else {
    q.style.backgroundColor = "unset";
    a.style.backgroundColor = "unset";
  }
  return true;
}

export function setBubbleSelectable(selectable) {
  document.body.style.setProperty("user-select", selectable ? "auto" : "none", "important");
  const bubbles = document.querySelectorAll(".Bubble");
  bubbles.forEach((bubble) => {
    bubble.style.userSelect = selectable ? "auto" : "none";
  });
}

// 删除滚动到底部提示语class
export const clearChatScrollBottom = () => {
  // const scrollbar = document.querySelector(".message-scrollbar");
  // if (!scrollbar) {
  //   return;
  // }
  // scrollbar.classList.remove("scrolling-bottom");
};

// messagelist 滚动到底部提示语
export const onChatScroll = (e) => {
  // const { target: element } = e;
  // let topValue = 0,
  //   interval = null;
  // function clear() {
  //   if (element.scrollTop === topValue) {
  //     element.classList.remove("scrolling-bottom");
  //     clearInterval(interval);
  //     interval = null;
  //   }
  // }
  // const bottom = isElementScrolledBottom(element);
  // bottom ? element.classList.add("scrolling-bottom") : element.classList.remove("scrolling-bottom");
  // if (!interval) {
  //   interval = setInterval(clear, 3000);
  // }
  // topValue = element.scrollTop;
};

// topmessage折叠
export const isElementCollision = (ele, rowCount = 1, cssStyles, removeChild) => {
  if (!ele) {
    return false;
  }

  const clonedNode = ele.cloneNode(true);
  // 给clone的dom增加样式
  clonedNode.style.overflow = "visible";
  clonedNode.style.display = "inline-block";
  clonedNode.style.width = "auto";
  clonedNode.style.whiteSpace = "nowrap";
  clonedNode.style.visibility = "hidden";
  // 将传入的css字体样式赋值
  if (cssStyles) {
    Object.keys(cssStyles).forEach((item) => {
      clonedNode.style[item] = cssStyles[item];
    });
  }

  // 给clone的dom增加id属性
  const containerID = "collision_node_id";
  clonedNode.setAttribute("id", containerID);

  let tmpNode = document.getElementById(containerID);
  let newNode = clonedNode;
  if (tmpNode) {
    document.body.replaceChild(clonedNode, tmpNode);
  } else {
    newNode = document.body.appendChild(clonedNode);
  }
  // 新增的dom宽度与原dom的宽度*限制行数做对比
  const differ = newNode.offsetWidth - ele.offsetWidth * rowCount;
  if (removeChild) {
    document.body.removeChild(newNode);
  }
  return differ > 0;
};
