import { memo } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import BubbleAction from "./BubbleAction/index";
import bubbleActionFilter from "./BubbleAction/filter";
import TextBubble from "./Bubble/TextBubble";
import PlaceholderBubble from "./PlaceholderBubble";
import TimesErrorBubble from "./Bubble/TimesErrorBubble";
import TotalLengthErrorBubble from "./Bubble/TotalLengthErrorBubble";
import LoadingBubble from "./Bubble/LoadingBubble";
import TypingBubble from "./Bubble/TypingBubble";
import MusicBubble from "./Bubble/MusicBubble";
import ImageBubble from "./Bubble/ImageBubble";
import ArxivBubble from "./ArxivBubble";
import GoogleBubble from "./GoogleBubble";
import PubmedBubble from "./PubmedBubble";
import CNKIBubble from "./CNKIBubble";
import WanfangBubble from "./WanfangBubble";
import WileyBubble from "./WileyBubble";
import ScienceBubble from "./ScienceBubble";
import SuggestionBubble from "./SuggestionBubble";
import GithubQABubble from "./GithubQABubble";
import FullTextBubble from "./FullTextBubble";
import style from "./index.module.less";
import { useMatch } from "react-router-dom";

function NormalMessage({
  text: originalText,
  createdAt,
  status,
  finished = true,
  setComposerText,
  messageId,
  messageType,
  position,
  isLast,
  questionType,
  placeholderType,
  isContent, // 文献判断是否是content生成的问答节点
  config, // 文件类文件附加信息
}) {
  const { text, actionType, actionText, actionList } = bubbleActionFilter(
    messageType,
    originalText,
    questionType,
    position,
    status
  );
  return (
    <BubbleAction
      messageId={messageId}
      text={text}
      status={status}
      setComposerText={setComposerText}
      actionType={actionType}
      actionText={actionText}
      actionList={actionList}
    >
      {position === "right" && MessageQuestionType({ questionType, config })}
      {NormalMessageBubble({
        text,
        createdAt,
        status,
        finished,
        setComposerText,
        messageId,
        messageType,
        position,
        isLast,
        questionType,
        placeholderType,
        isContent,
        config, // 文件类文件附加信息
      })}
    </BubbleAction>
  );
}

function MessageQuestionType({ questionType, config }) {
  const matchAtDocument = useMatch("/document");
  const { pubmedTypes, db, analysisType, language, online, knowledge } = config || [];
  return (
    <>
      {matchAtDocument && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`提问模式：${questionType}`}>
            <span>{questionType}</span>
          </Tooltip>
        </p>
      )}
      {pubmedTypes && pubmedTypes.length > 0 && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`PubMed分类：${pubmedTypes.join()}`}>
            <span>{pubmedTypes.join()}</span>
          </Tooltip>
        </p>
      )}
      {db && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`网络文献：${db}`}>
            <span>{db}</span>
          </Tooltip>
        </p>
      )}
      {analysisType && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`分析类型：${analysisType}`}>
            <span>{analysisType}</span>
          </Tooltip>
        </p>
      )}
      {language && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`目标语言：${language}`}>
            <span>{language}</span>
          </Tooltip>
        </p>
      )}
      {online && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={"在线搜索"}>
            <span>{"在线搜索"}</span>
          </Tooltip>
        </p>
      )}
      {knowledge && (
        <p className={style.bubbleQuestionType}>
          <Tooltip title={`个人文献：${knowledge["name"]}`}>
            <span>{knowledge["name"]}</span>
          </Tooltip>
        </p>
      )}
    </>
  );
}

function NormalMessageBubble({
  text,
  createdAt,
  status,
  finished,
  setComposerText,
  messageId,
  messageType,
  position,
  isLast,
  questionType,
  placeholderType,
  isContent,
  config,
}) {
  switch (messageType) {
    case "text":
      return (
        <TextBubble
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
        />
      );
    case "img":
      return (
        <ImageBubble
          questionType={questionType}
          text={text}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
        />
      );
    case "music":
      return (
        <MusicBubble
          questionType={questionType}
          text={text}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
        />
      );
    case "file":
      return (
        <TextBubble
          fileList={config}
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
        />
      );
    case "arxiv-scholar":
      return (
        <ArxivBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          questionType={questionType}
          isLast={isLast}
        />
      );
    case "google-scholar":
      return (
        <GoogleBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          questionType={questionType}
          isLast={isLast}
        />
      );
    case "pubmed-scholar":
      return (
        <PubmedBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          questionType={questionType}
          isLast={isLast}
        />
      );
    case "cnki-scholar":
      return (
        <CNKIBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "wanfang-scholar":
      return (
        <WanfangBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "wiley-scholar":
      return (
        <WileyBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "suggestion-scholar":
      return (
        <SuggestionBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "science-scholar":
      return (
        <ScienceBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "github-QA":
      return (
        <GithubQABubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "fulltext-search":
      return (
        <FullTextBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "message-loading":
      return <LoadingBubble />;
    case "message-typing":
      return <TypingBubble />;
    case "total-length":
      return <TotalLengthErrorBubble error={text} />;
    case "no-times":
      return <TimesErrorBubble error={text} />;
    case "plugin-placeholder":
      return <PlaceholderBubble type={placeholderType} />;

    default:
      return (
        <TextBubble
          questionType={questionType}
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
        />
      );
  }
}

export default memo(NormalMessage);
