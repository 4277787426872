import AudioUpload from "./AudioUpload";
import DataUpload from "./DataUpload";
import FileUpload from "./FileUpload";
import ImageUpload from "./ImageUpload";
import OfficeUpload from "./OfficeUpload";
import ArticleUpload from "./ArticleUpload";
import ENWUpload from "./ENWUpload";

function UploadButton({ quickReply, setFileList, setSendDisable, fileList, callback }) {
  switch (quickReply) {
    case "数据分析":
    case "批处理":
      return (
        <DataUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
        />
      );
    case "OFFICE密码破解":
      return (
        <OfficeUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
          callback={callback}
        />
      );
    case "语音转文字":
      return (
        <AudioUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
          callback={callback}
        />
      );
    case undefined:
    case null:
      return (
        <FileUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
        />
      );
    case "OCR图文识别":
    case "图片解释":
    case "代码重现":
    case "图生论文":
      return (
        <ImageUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
        />
      );
    case "图表数字化":
      return (
        <ImageUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
          callback={callback}
        />
      );
    case "文章读写":
    case "文章重组":
    case "文章优化":
      return (
        <ArticleUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
        />
      );
    case "循证问答":
    case "开题报告":
    case "论文撰写":
    case "文献综述":
      return (
        <ENWUpload
          setFileList={setFileList}
          setSendDisable={setSendDisable}
          fileList={fileList}
        />
      );

    default:
      break;
  }
}

export default UploadButton;
