import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Space } from "antd";
import { BarsOutlined } from "@ant-design/icons";
import useScreenSize from "@/hooks/useScreenSize";
import WindowToolbar from "./WindowToolbar";
import ModelSegmented from "./ModelSegmented";
import EditableTitle from "./EditableTitle";
import HeadTongue from "./HeadTongue";
import styles from "./index.module.less";
import classNames from "classnames";

function WindowHeader({ showSider, siderHandler }) {
  const navigate = useNavigate();
  const { middle } = useScreenSize();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const navigateHandler = useCallback(
    async (to) => {
      switch (pathname) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession();
          break;
        case "/document":
          navigate(to, { replace: true, show: true });
          dispatch.document.resetSession();
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname]
  );

  return (
    <div className={styles.header}>
      <div
        className={classNames("head-right", {
          "full-width": !showSider,
          "zero-width": showSider,
        })}
      >
        {middle ? (
          <EditableTitle siderHandler={siderHandler} />
        ) : (
          <div className="mobile-head-sider">
            <Button
              type="text"
              size="large"
              icon={<BarsOutlined />}
              onClick={() => siderHandler(true)}
            />
          </div>
        )}
        {middle ? (
          <ModelSegmented />
        ) : (
          <div className="head-bottom">
            <HeadTongue />
          </div>
        )}
        <WindowToolbar />
      </div>
    </div>
  );
}

export default WindowHeader;
